@font-face {
	font-family: "Inter";
	src: url("../../assets/fonts/Inter-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Inter";
	src: url("../../assets/fonts/Inter-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: medium;
}
@font-face {
	font-family: "Inter";
	src: url("../../assets/fonts/Inter-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: bold;
}
@font-face {
	font-family: "AtlasGrotesk";
	src: url("../../assets/fonts/AtlasGrotesk-Black.otf") format("opentype");
	font-weight: 700;
	font-style: bold;
}
@font-face {
	font-family: "AtlasGrotesk";
	src: url("../../assets/fonts/AtlasGrotesk-Regular.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "AtlasGrotesk";
	src: url("../../assets/fonts/AtlasGrotesk-Medium.otf") format("opentype");
	font-weight: 500;
	font-style: medium;
}
