@use "../../styles/partials/colors" as *;
@use "../../styles/partials/breakpoints" as breakpoint;
@use "../../styles/partials/mixins" as *;

.header {
	background-color: $primary-color;
	width: 100%;
	height: 5rem;
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	position: fixed;
	z-index: 99999;
	top: 0;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	transition: all 0.3s ease-in-out;

	&__container {
		max-width: 80rem;
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0rem 4rem;
	}
}

.navbar {
	&__logo {
		color: $light-navyblue-color;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		div {
			width: 0.3125rem;
			height: 0.3125rem;
			background: transparent;
			border-radius: 50%;
			margin-bottom: 0.3125rem;
		}
	}

	&__list {
		flex: 0.5;
		display: flex;
		align-items: center;
		justify-content: center;

		&-item {
			margin: 0 1rem;
			display: flex;
			align-items: center;
			flex-direction: column;
			cursor: pointer;

			&--active {
				text-decoration: none;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-bottom: 0.25;
				color: $navyblue-color;
			}

			div {
				width: 0.3125rem;
				height: 0.3125rem;
				background: transparent;
				border-radius: 50%;
				margin-bottom: 5px;
			}

			&:hover {
				div {
					background: $navyblue-color;
				}
			}
		}
	}

	&__link {
		text-decoration: none;
		color: $gray-color;
		font-weight: 500;
		transition: all 0.3s ease-in-out;

		&-btn {
			border: 1px solid $navyblue-color;
			padding: 0.7rem 2rem 0.7rem 2rem;
			color: $gray-color;
			font-weight: 500;
			border: 1px solid $gray-color;
			border-radius: 0.5rem;
			font-family: "AtlasGrotesk", "inter", sans-serif;
			display: inline-block;

			&:hover {
				color: $lightest-navyblue-color;
				transition: all 0.3s ease-in-out;
			}
		}

		&--active {
			text-decoration: none;
			color: $navyblue-color;
			font-weight: 500;
		}

		&:hover {
			color: $lightest-navyblue-color;
		}
	}

	&__mobile-menu {
		display: none;
	}
}

.mobile {
	display: flex;
}

/*  Mobile */
@include breakpoint.small {
	.header {
		&__container {
			padding: 0rem 2rem;
		}
	}
	.navbar {
		&__list {
			display: none;
		}

		&__link {
			text-decoration: none;
			color: $gray-color;
			font-weight: 500;
			transition: all 0.3s ease-in-out;

			&-btn {
				border: 1px solid $navyblue-color;
				padding: 0.7rem 2rem 0.7rem 2rem;
				margin: 0rem 0rem 1.5rem;
				color: $gray-color;
				font-weight: 500;
				border: 1px solid $gray-color;
				border-radius: 0.5rem;
				font-family: "AtlasGrotesk", "inter", sans-serif;
				display: inline-block;

				&:hover {
					color: $lightest-navyblue-color;
					transition: all 0.3s ease-in-out;
				}
			}

			&--active {
				text-decoration: none;
				color: $navyblue-color;
				font-weight: 500;
			}

			&:hover {
				color: $lightest-navyblue-color;
			}
		}
	}

	.mobile {
		background-color: #f5f5f5;
		flex: 0.5;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		display: block;
		left: 0;
		top: 3.8125rem;
		transition: all 0.5s ease-out;
		width: 100%;

		&-menu {
			display: flex;

			&--btn {
				background: none;
				border: none;
				width: 2rem;
				height: 2rem;
				filter: invert(9%) sepia(51%) saturate(1573%) hue-rotate(193deg) brightness(100%)
					contrast(95%);
				&:hover {
					color: red;
				}
			}
			&--icon {
				background: none;
				border: none;
				width: 2rem;
				height: 2rem;
			}
		}

		&__list-item {
			margin: 1.5rem 0rem;
			display: flex;
			align-items: center;
			flex-direction: column;
			cursor: pointer;

			div {
				width: 0.3125rem;
				height: 0.3125rem;
				background: transparent;
				border-radius: 50%;

				margin-bottom: 5px;
			}
			&:hover {
				div {
					background: $navyblue-color;
				}
			}

			&--active {
				text-decoration: none;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-bottom: 0.25;
				color: $navyblue-color;
				border-bottom: 3px solid $white-color;
			}
		}
	}
}

/*  TABLET  */
@include breakpoint.medium {
	.header {
		background-color: $primary-color;
		width: 100%;
		height: 5rem;
		backdrop-filter: blur(4px);
		-webkit-backdrop-filter: blur(4px);
		position: fixed;
		z-index: 99999;
		top: 0;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
		transition: all 0.3s ease-in-out;

		&__container {
			max-width: 80rem;
			height: 100%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0rem 3rem;
		}
	}

	.navbar {
		&__logo {
			color: $light-navyblue-color;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		&__list {
			flex: 0.5;
			display: flex;
			align-items: center;
			justify-content: center;

			&-item {
				margin: 0 0.75rem;
				display: flex;
				align-items: center;
				flex-direction: column;
				cursor: pointer;

				&--active {
					text-decoration: none;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: $navyblue-color;
				}

				div {
					width: 0.3125rem;
					height: 0.3125rem;
					background: transparent;
					border-radius: 50%;
					margin-bottom: 5px;
				}

				&:hover {
					div {
						background: $navyblue-color;
					}
				}
			}
		}

		&__link {
			text-decoration: none;
			color: $gray-color;
			font-weight: 500;
			transition: all 0.3s ease-in-out;

			&-btn {
				border: 1px solid $navyblue-color;
				padding: 0.5rem 1rem 0.5rem;
				color: $gray-color;
				font-weight: 500;
				border: 1px solid $gray-color;
				border-radius: 0.5rem;
				font-family: "AtlasGrotesk", "inter", sans-serif;
				display: inline-block;

				&:hover {
					color: $lightest-navyblue-color;
					transition: all 0.3s ease-in-out;
				}
			}

			&--active {
				text-decoration: none;
				color: $navyblue-color;
				font-weight: 500;
			}

			&:hover {
				color: $lightest-navyblue-color;
			}
		}
	}
	.mobile-menu {
		display: none;
	}
}

/* Desktop  */
@include breakpoint.large {
	.header {
		background-color: $primary-color;
		width: 100%;
		height: 5rem;
		backdrop-filter: blur(4px);
		-webkit-backdrop-filter: blur(4px);
		position: fixed;
		z-index: 99999;
		top: 0;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
		transition: all 0.3s ease-in-out;

		&__container {
			max-width: 80rem;
			height: 100%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0rem 4rem;
		}
	}

	.navbar {
		&__logo {
			color: $light-navyblue-color;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			div {
				width: 0.3125rem;
				height: 0.3125rem;
				background: transparent;
				border-radius: 50%;
				margin-bottom: 5px;
			}
		}

		&__list {
			flex: 0.5;
			display: flex;
			align-items: center;
			justify-content: center;

			&-item {
				margin: 0 1rem;
				display: flex;
				align-items: center;
				flex-direction: column;
				cursor: pointer;

				&--active {
					text-decoration: none;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					padding-bottom: 0.25;
					color: $navyblue-color;
					border-bottom: 3px solid $white-color;
				}

				div {
					width: 0.3125rem;
					height: 0.3125rem;
					background: transparent;
					border-radius: 50%;
					margin-bottom: 5px;
				}

				&:hover {
					div {
						background: $navyblue-color;
					}
				}
			}
		}

		&__link {
			text-decoration: none;
			color: $gray-color;
			font-weight: 500;
			transition: all 0.3s ease-in-out;

			&-btn {
				border: 1px solid $navyblue-color;
				padding: 0.5rem 1rem 0.5rem;
				color: $gray-color;
				font-weight: 500;
				border: 1px solid $gray-color;
				border-radius: 0.5rem;
				font-family: "AtlasGrotesk", "inter", sans-serif;
				display: inline-block;

				&:hover {
					color: $lightest-navyblue-color;
					transition: all 0.3s ease-in-out;
				}
			}

			&--active {
				text-decoration: none;
				color: $navyblue-color;
				font-weight: 500;
			}

			&:hover {
				color: $lightest-navyblue-color;
			}
		}
	}
	.mobile-menu {
		display: none;
	}
}
