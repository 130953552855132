$mobile-max-width: 767px;
$tablet-min-width: 768px;
$desktop-min-width: 1280px;
// $desktop-max-width-container: 1020px;

/* media query for mobile */
@mixin small {
	@media only screen and (max-width: $mobile-max-width) {
		/* placeholder for css properties that needs to be applied for this media query */
		@content;
	}
}

/* media query for tablet */
@mixin medium {
	@media only screen and (min-width: $tablet-min-width) {
		@content;
	}
}

/* media query for high pixel density devices */
@mixin large {
	@media only screen and (min-width: $desktop-min-width) {
		@content;
	}
}
