@use "../../styles/partials/colors" as *;
@use "../../styles/partials/breakpoints" as breakpoint;
@use "../../styles/partials/mixins" as *;

.about {
	flex: 1;
	width: 100%;
	min-height: 40.3125rem;
	background-color: $primary-color;

	&__container {
		max-width: 80rem;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	&__content {
		flex: 1;
		display: flex;
		align-items: center;
		gap: 12%;
	}

	&__right-side {
		width: 50%;
	}
	&__left-side {
		width: 50%;
	}

	&__profile-image {
		background-color: gray;
		background: url("../../assets/images/side_profile.webp");
		background-size: cover;
		background-repeat: repeat;
		background-position: center;
		height: 20rem;
		width: 20rem;
		border-radius: 50%;
		// box-shadow: 0 0 20px rgba(0, 0, 0, 1);
		filter: grayscale(100%) opacity(0.7);

		&:hover {
			filter: none;
		}
	}

	&__header {
		&--text {
			color: $light-navyblue-color;
			@include desktopPageHeader;
			margin: 4rem 0 2rem;
		}
	}
}

.paragraph {
	@include desktopBodyLarge;
	color: $navyblue-color;
	font-weight: 500;
}

.transcript {
	color: $gray-color;
	font-weight: 400;
}
.highlight {
	color: $navyblue-color;
	font-weight: 700;
}

/*  MOBILE  */
@include breakpoint.small {
	.about {
		flex: 1;
		width: 100%;
		min-height: 40.3125rem;
		background-color: $primary-color;

		&__container {
			padding: 0rem 2rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

		&__content {
			flex: 1;
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
		}

		&__right-side {
			width: 100%;
			margin-bottom: 2rem;
		}

		&__profile-image {
			height: 17rem;
			width: 17rem;
			border-radius: 50%;
			// box-shadow: 0 0 20px rgba(0, 0, 0, 1);
			margin-bottom: 2rem;
		}

		&__header {
			&--text {
				color: $light-navyblue-color;
				@include desktopPageHeader;
				margin: 2rem 0 2rem;
			}
		}
	}
}

/*  TABLET  */
@include breakpoint.medium {
	.about {
		flex: 1;
		width: 100%;
		min-height: 40.3125rem;
		background-color: $primary-color;

		&__container {
			padding: 0 3rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

		&__content {
			flex: 1;
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;
			align-items: center;
			gap: 12%;
		}

		&__right-side {
			width: 100%;
			margin-bottom: 3rem;
		}

		&__profile-image {
			height: 20rem;
			width: 20rem;
			border-radius: 50%;
			// box-shadow: 0 0 20px rgba(0, 0, 0, 1);
			margin-bottom: 3rem;
		}

		&__header {
			&--text {
				color: $light-navyblue-color;
				@include desktopPageHeader;
				margin: 3rem 0 2rem;
			}
		}
	}
}

/*  DESKTOP  */
@include breakpoint.large {
	.about {
		flex: 1;
		width: 100%;
		min-height: 40.3125rem;
		background-color: $primary-color;

		&__container {
			max-width: 80rem;
			margin: 0 auto;
			padding: 0 4rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

		&__content {
			flex: 1;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 12%;
		}

		&__right-side {
			width: 50%;
		}
		&__left-side {
			width: 50%;
		}

		&__header {
			&--text {
				color: $light-navyblue-color;
				@include desktopPageHeader;
				margin: 4rem 0 2rem;
			}
		}
	}

	.paragraph {
		@include desktopBodyLarge;
		color: $navyblue-color;
	}
}
