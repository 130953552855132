//primary colors
$primary-color: #edf2f8;
$secondary-color: #313bac;

$white-color: #ffffff;

$gray-color: #95a5a6;

$black-color: #030303;

$silver-color: #afafaf;

$silver2-color: #d5d5d5;

$navyblue-color: #0a192f;

$light-navyblue-color: #112240;

$lightest-navyblue-color: #233554;
