@use "../../styles/partials/colors" as *;
@use "../../styles/partials/breakpoints" as breakpoint;
@use "../../styles/partials/mixins" as *;

.works {
	flex: 1;
	width: 100%;
	background-color: $primary-color;

	&__container {
		max-width: 80rem;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		margin-top: 3rem;
		gap: 5%;
	}

	&__header--text {
		color: $light-navyblue-color;
		@include desktopPageHeader;
	}
}

.project {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin: 4rem 0 4rem;

	&__content {
		width: 40%;

		&--rev {
			width: 40%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}
	&__overline {
		@include desktopLabelsLinksButtons;
		margin: 0.625rem 0;
		color: $light-navyblue-color;
		font-weight: 400;
	}
	&__title {
		@include desktopSubheader;
		color: $light-navyblue-color;
		margin: 0 0 1.25rem;
		font-weight: 500;
	}

	&__description {
		box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
		transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
		position: relative;
		z-index: 2;
		padding: 1.5625rem;
		border-radius: 0.25rem;
		background-color: $light-navyblue-color;
		color: $white-color;
		@include desktopBodyLarge;
	}

	&__tech-list {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 2;
		margin: 1.5625rem 0 0.625rem;
		padding: 0;
		list-style: none;

		&--item {
			@include desktopLabelsLinksButtons;
			margin: 0rem 1.25rem 0.3125rem 0rem;
			color: $gray-color;
			white-space: nowrap;
		}
		&--item-rev {
			@include desktopLabelsLinksButtons;
			margin: 0rem;
			padding-left: 1rem;
			color: $gray-color;
			white-space: nowrap;
		}
	}

	&__image {
		width: 50%;
		height: 23.4375rem;
		max-width: 43.75rem;
		display: block;
		border-radius: 25px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
		filter: grayscale(50%) contrast(0.8) brightness(90%) opacity(0.5);
		transition: opacity 2s ease-in-out 0s;

		margin-left: -10rem;

		&:hover {
			filter: none;
			margin-left: 3.125rem;
			transition: all 2s ease-in-out;
		}

		&--img {
			width: 100%;
			height: 100%;
			border-radius: 25px;
		}
	}

	&__image--rev {
		width: 50%;
		height: 23.4375rem;
		max-width: 43.75rem;
		display: block;
		border-radius: 25px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
		filter: grayscale(50%) contrast(0.8) brightness(90%) opacity(0.5);
		transition: opacity 2s ease-in-out 0s;

		margin-right: -10rem;

		&:hover {
			filter: none;
			margin-right: 3.125rem;
			transition: all 2s ease-in-out;
		}

		&--img {
			width: 100%;
			height: 100%;
			border-radius: 25px;
		}
	}

	&__links {
		display: flex;
		gap: 5%;

		&-item {
			filter: invert(49%) sepia(14%) saturate(498%) hue-rotate(179deg) brightness(90%)
				contrast(91%);
			&:hover {
				filter: invert(9%) sepia(51%) saturate(1573%) hue-rotate(193deg) brightness(100%)
					contrast(95%);
			}
			&--img {
				width: 1.75rem;
				height: 1.75rem;
			}
		}
	}
	&__links--rev {
		display: flex;
		justify-content: flex-end;
		gap: 36%;
	}
}

/*  MOBILE  */
@include breakpoint.small {
	.works {
		&__container {
			margin: 0;
			padding: 2rem;
		}

		&__content {
			margin-top: 0;
		}

		&__header--text {
			color: $light-navyblue-color;
			@include mobilePageHeader;
		}
	}

	.project {
		margin: 2rem 0 2rem;
		&__image {
			width: 100%;
			max-height: 18.75rem;
			min-width: 18.75rem;
			max-width: 28.125rem;
			display: block;
			border-radius: 1.5625rem;
			box-shadow: 0 0 20px rgba(0, 0, 0, 1);
			filter: grayscale(90%) contrast(0.8) brightness(90%) opacity(0.1);
			transition: opacity 2s ease-in-out 0s;
			margin-left: -19.6rem;
			display: none;

			&:hover {
				filter: grayscale(90%) contrast(0.8) brightness(90%) opacity(0.1);
				margin-left: -19.6rem;
				transition: none;
			}

			&--rev {
				width: 100%;
				max-height: 18.75rem;
				min-width: 17.75rem;
				max-width: 28.125rem;
				display: block;
				border-radius: 1.5625rem;
				box-shadow: 0 0 20px rgba(0, 0, 0, 1);
				filter: grayscale(90%) contrast(0.8) brightness(90%) opacity(0.1);
				transition: opacity 2s ease-in-out 0s;
				margin-right: -17.6rem;
				display: none;

				&:hover {
					filter: grayscale(90%) contrast(0.8) brightness(90%) opacity(0.1);
					margin-right: -17.6rem;
					transition: none;
				}
			}
		}
		&__content {
			width: 100%;
			background-color: rgba(10, 25, 47, 0.95);
			padding: 2rem;
			border-radius: 1.5625rem;

			&--rev {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				background-color: rgba(10, 25, 47, 0.95);
				padding: 2rem;
				border-radius: 1.5625rem;
				text-align: start;
			}
		}

		&__overline {
			@include desktopLabelsLinksButtons;
			margin: 0.625rem 0;
			color: $silver-color;
			font-weight: 400;
		}
		&__title {
			@include desktopSubheader;
			color: $white-color;
			margin: 0 0 1.25rem;
			font-weight: 500;
		}

		&__description {
			box-shadow: none;
			transition: none;
			background: none;
			position: relative;
			z-index: 2;
			padding: 0;
			border-radius: 0;
			color: $white-color;
			@include desktopBodyLarge;
		}

		&__tech-list {
			display: flex;
			flex-wrap: wrap;
			position: relative;
			z-index: 2;
			margin: 1.5625rem 0 0.625rem;
			padding: 0;
			list-style: none;

			&--item {
				@include desktopLabelsLinksButtons;
				margin: 0rem 1.25rem 0.3125rem 0rem;
				color: $silver-color;
				white-space: nowrap;
			}
			&--item-rev {
				@include desktopLabelsLinksButtons;
				margin: 0rem 1.25rem 0.3125rem 0rem;
				padding-left: 0;
				color: $silver-color;
				white-space: nowrap;
			}
		}

		&__links-item {
			filter: invert(78%) sepia(0%) saturate(0%) hue-rotate(229deg) brightness(91%)
				contrast(88%);
			&:hover {
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(14deg) brightness(108%)
					contrast(101%);
			}
		}
		&__links--rev {
			display: flex;
			justify-content: flex-start;
			gap: 19%;
		}
	}
}

/*  TABLET  */
@include breakpoint.medium {
	.works {
		&__container {
			margin: 0;
			padding: 3rem;
		}

		&__content {
			margin-top: 0;
		}
	}

	.project {
		margin: 3rem 0 3rem;
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;

		&__content {
			width: 40%;

			&--rev {
				width: 40%;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				text-align: end;
			}
		}

		&__image {
			width: 55%;
			height: 17.5625rem;
			max-height: 19.3125rem;
			min-width: 31.25rem;
			max-width: 34.375rem;
			display: block;
			border-radius: 25px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
			filter: grayscale(90%) contrast(0.4) brightness(90%) opacity(0.4);
			transition: opacity 0.4s ease-in-out 0s;

			margin-left: -10rem;

			&:hover {
				filter: none;
				margin-left: -10rem;
				transition: all 0.4s ease-in-out 0s;
			}

			&--img {
				width: 100%;
				height: 100%;
				border-radius: 25px;
			}
		}

		&__image--rev {
			width: 55%;
			height: 17.5625rem;
			max-height: 19.3125rem;
			min-width: 31.25rem;
			max-width: 34.375rem;
			display: block;
			border-radius: 25px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
			filter: grayscale(90%) contrast(0.4) brightness(90%) opacity(0.4);
			transition: opacity 0.4s ease-in-out 0s;

			margin-right: -10rem;

			&:hover {
				filter: none;
				margin-right: -10rem;
				transition: all 0.4s ease-in-out 0s;
			}

			&--img {
				width: 100%;
				height: 100%;
				border-radius: 25px;
			}
		}
	}
}

/*  DESKTOP  */
@include breakpoint.large {
	.works {
		&__container {
			max-width: 80rem;
			margin: 0 auto;
			padding: 4rem;
		}
	}

	.project {
		margin: 4rem 0 4rem;

		&__content {
			width: 40%;

			&--rev {
				width: 40%;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
			}
		}

		&__image {
			width: 50%;
			height: 20.1875rem;
			width: 35.9375rem;
			display: block;
			border-radius: 1.5625rem;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
			filter: grayscale(90%) contrast(0.4) brightness(90%) opacity(0.5);
			transition: all 0.6s ease-in-out 0s;

			margin-left: -10rem;

			&:hover {
				filter: none;
				margin-left: 3.125rem;
				transition: all 0.6s ease-in-out 0s;
			}

			&--img {
				width: 100%;
				height: 100%;
				border-radius: 25px;
			}
		}

		&__image--rev {
			width: 50%;
			height: 20.1875rem;
			width: 35.9375rem;
			display: block;
			border-radius: 25px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
			filter: grayscale(50%) contrast(0.8) brightness(90%) opacity(0.5);
			transition: all 0.6s ease-in-out 0s;

			margin-right: -10rem;

			&:hover {
				filter: none;
				margin-right: 3.125rem;
				transition: all 0.6s ease-in-out;
			}

			&--img {
				width: 100%;
				height: 100%;
				border-radius: 25px;
			}
		}
	}
}
