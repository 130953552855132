@use "../../styles/partials/colors" as *;
@use "../../styles/partials/breakpoints" as breakpoint;
@use "../../styles/partials/mixins" as *;

.footer {
	background-color: $primary-color;
	width: 100%;

	&__container {
		max-width: 80rem;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 2rem;
	}

	&__note {
		@include desktopBodyLarge;
		margin: 0.7rem 0;
		color: $navyblue-color;
		font-weight: 500;
	}

	&__socials {
		&-link {
			margin: 0.5rem;
			filter: invert(49%) sepia(14%) saturate(498%) hue-rotate(179deg) brightness(90%)
				contrast(91%);
			&:hover {
				filter: invert(9%) sepia(51%) saturate(1573%) hue-rotate(193deg) brightness(100%)
					contrast(95%);
			}
		}
		&-img {
			width: 2rem;
			height: 2rem;
			color: $navyblue-color;
		}
	}
}

/*  MOBILE  */
@include breakpoint.small {
	.footer {
		&__container {
			flex-direction: column;
		}
	}
}

/*  TABLET  */
@include breakpoint.medium {
	.footer {
		&__container {
			padding: 1.5rem 3rem;
		}
	}
}

/*  DESKTOP  */
@include breakpoint.large {
	.footer {
		&__container {
			padding: 2rem 4rem;
		}
	}
}
