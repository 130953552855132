@use "../../styles/partials/colors" as *;
@use "../../styles/partials/breakpoints" as breakpoint;
@use "../../styles/partials/mixins" as *;

.hero {
	flex: 1;
	width: 100%;
	height: 100vh;

	&__container {
		max-width: 80rem;
		margin: 1rem auto;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	&__content {
		width: 100%;
		padding: 3rem 3rem;
		border-radius: 1rem;
		// box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

		&--intro {
			@include desktopBodyLarge;
			color: $silver-color;
			font-weight: 500;
		}

		&--name {
			font-size: 5rem;
			color: $primary-color;
			margin-top: 2rem;
			margin-bottom: 1rem;
		}
		&--caption {
			font-size: 4rem;
			color: $primary-color;
		}
		&--typewritten {
			font-size: 4rem;
			color: $gray-color;
		}
		&--bio {
			@include desktopBodyLarge;
			color: $silver-color;
			margin-bottom: 3rem;
			font-weight: 500;
		}
	}

	.connect {
		&__btn {
			width: 10.9375rem;
			padding: 0.7rem 2rem 0.7rem 2rem;
			color: $gray-color;
			font-weight: 500;
			border: 1px solid $gray-color;
			border-radius: 0.5rem;
			font-family: "AtlasGrotesk", "inter", sans-serif;
			display: inline-block;

			&:hover {
				box-shadow: inset 200px 0 0 0 $lightest-navyblue-color;
				color: #fff;
				transition: all 0.6s ease-in-out;
			}
		}
	}
}

/*  MOBILE   */
@include breakpoint.small {
	.hero {
		flex: 1;
		width: 100%;
		height: 75vh;
		padding-top: 5rem;

		&__container {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0rem 2rem;
			height: 100%;
			width: 100%;
			margin: 0;
			background-color: $navyblue-color;
		}
		&__content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: 2rem 0rem;
			background-color: none;
			border-radius: 0;
			box-shadow: none;

			&--intro {
				@include desktopBodyLarge;
				color: $silver2-color;
				font-weight: 500;
			}

			&--name {
				font-size: 2.25rem;
				color: $primary-color;
				line-height: 2.5rem;
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
			&--caption {
				font-size: 1.2rem;
				line-height: 2.5rem;
				color: $primary-color;
				margin-bottom: 1rem;
			}
			&--typewritten {
				font-size: 1.2rem;
				color: $gray-color;
			}
			&--bio {
				@include desktopBodyLarge;
				color: $silver2-color;
				margin-bottom: 3rem;
				font-weight: 500;
			}
		}
	}
	.connect {
		&__btn {
			width: 10.9375rem;
			padding: 0.7rem 2rem 0.7rem 2rem;
			background-color: transparent;
			font-weight: 500;
			border: none;
			border-radius: 0.5rem;
			display: inline-block;
			color: $gray-color;
			border: 1px solid $gray-color;
			font-family: "AtlasGrotesk", "inter", sans-serif;
			display: inline-block;

			&:hover {
				box-shadow: inset 200px 0 0 0 $navyblue-color;

				color: $white-color;
				border: 1px solid $white-color;
				transition: all 0.6s ease-in-out;
			}
		}
	}
}

/*  TABLET  */
@include breakpoint.medium {
	.hero {
		flex: 1;
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 5rem;

		&__container {
			margin: 2rem 3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 75%;
			width: 100%;
		}

		&__content {
			width: 100%;
			height: auto;
			padding: 3rem 3rem;
			background-color: $navyblue-color;
			border-radius: 1rem;
			// box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

			&--intro {
				@include desktopBodyLarge;
				color: $silver-color;
				font-weight: 500;
			}

			&--name {
				font-size: 3.75rem;
				color: $primary-color;
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
			&--caption {
				font-size: 2.5rem;
				color: $primary-color;
				margin-bottom: 2rem;
			}
			&--typewritten {
				font-size: 2.5rem;
				color: $silver-color;
			}
		}

		.connect {
			&__btn {
				width: 10.9375rem;
				padding: 0.7rem 2rem 0.7rem 2rem;
				color: $gray-color;
				font-weight: 500;
				border: 1px solid $gray-color;
				border-radius: 0.5rem;
				font-family: "AtlasGrotesk", "inter", sans-serif;
				display: inline-block;

				&:hover {
					box-shadow: inset 200px 0 0 0 $navyblue-color;
					border: 1px solid $white-color;
					color: $white-color;
					transition: all 0.6s ease-in-out;
				}
			}
		}
	}
}

/*  DESKTOP  */
@include breakpoint.large {
	.hero {
		flex: 1;
		width: 100%;
		height: 100vh;

		&__container {
			max-width: 80rem;
			margin: 1rem auto;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
		}

		&__content {
			width: 100%;
			height: auto;
			margin: 0rem 4rem;
			padding: 3rem 3rem;
			background-color: $navyblue-color;
			border-radius: 1rem;
			// box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

			&--intro {
				@include desktopBodyLarge;
				color: $silver-color;
				font-weight: 500;
			}

			&--name {
				font-size: 5rem;
				color: $primary-color;
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
			&--caption {
				font-size: 4rem;
				color: $primary-color;
				margin-bottom: 2rem;
			}
			&--typewritten {
				font-size: 4rem;
				color: $gray-color;
			}
		}
		.connect {
			&__btn {
				width: 10.9375rem;
				padding: 0.7rem 2rem 0.7rem 2rem;
				color: $gray-color;
				font-weight: 500;
				border: 1px solid $gray-color;
				border-radius: 0.5rem;
				font-family: "AtlasGrotesk", "inter", sans-serif;
				display: inline-block;

				&:hover {
					box-shadow: inset 200px 0 0 0 $navyblue-color;
					border: 1px solid $white-color;
					color: $white-color;
					transition: all 0.6s ease-in-out;
				}
			}
		}
	}
}
