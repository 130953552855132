@use "./colors" as *;
@use "./fonts" as *;

// Desktop and Tablet Section
@mixin desktopPageHeader {
	font-size: 2rem;
	line-height: 2.5;
	font-weight: 700;
}

@mixin desktopSubheader {
	font-size: 1.5rem;
	line-height: 2;
	font-weight: 500;
}

@mixin desktopLabelsLinksButtons {
	font-size: 0.875rem;
	line-height: 1.375;
	font-weight: 500;
}

@mixin desktopTableHeader {
	font-size: 0.75rem;
	line-height: 1.125;
	font-weight: 400;
}

@mixin desktopBodyLarge {
	font-size: 1rem;
	line-height: 1.75;
	font-weight: 400;
}

@mixin desktopBodyMedium {
	font-size: 0.875rem;
	line-height: 1.375rem;
	font-weight: 400;
}

@mixin desktopBodySmall {
	font-size: 0.75rem;
	line-height: 1.125rem;
	font-weight: 400;
}

// Mobile Section

@mixin mobilePageHeader {
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 700;
}

@mixin mobileSubheader {
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 500;
}

@mixin mobileLabelsLinksButtons {
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: 500;
}

@mixin mobileTableHeader {
	font-size: 0.6875rem;
	line-height: 1rem;
	font-weight: 500;
}

@mixin mobileBodyLarge {
	font-size: 0.9375rem;
	line-height: 1.625rem;
	font-weight: 400;
}

@mixin mobileBodyMedium {
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: 400;
}

@mixin mobileBodySmall {
	font-size: 0.6875rem;
	line-height: 1rem;
	font-weight: 400;
}

// box shadow

@mixin boxShadow {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

//Buttons

@mixin buttonPrimary {
	background: #0361ac;
	border-radius: 1.25rem;
	padding: 0.5rem;
	color: #ffff;
	border: none;
	font-family: inter;

	&:hover {
		background: #02457a;
	}
}
@mixin buttonSecondary {
	background: #0361ac;
	border-radius: 1.25rem;
	padding: 0.5rem;
	color: #ffff;
	border: 1px solid #02457a;
	font-family: inter;

	&:hover {
		background: #02457a;
		color: #ffff;
	}
}
